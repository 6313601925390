<template>
  <div>
    <p>Navbar :sunglasses:</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 35%);
}
</style>