import Vue from 'vue'
import Vuex from 'vuex'
import * as Pretty from "pretty-number";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    movieCount: Pretty(600000)
  },
  mutations: {
    "SOCKET_movieCount"(state, data) {
      console.log(data)
      state.movieCount = Pretty(data)
    }
  },
  actions: {
  },
  modules: {
  }
})
