<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Welcome!</h1>
      <p class="subtitle">Currently analyzing {{ $store.state.movieCount }}+ movies</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
  },
  components: {},
};
</script>
